<template>
 <div>
    <v-row no-gutters class="pa-2">
        <v-col cols="12">
          <v-card v-if="!isOnline" flat class="text-center pa-4">
            
            <p style="margin-top: 120px;">
              <v-btn  x-large fab><v-icon>mdi-wifi-off</v-icon></v-btn>
            </p>
              Please connect to the Internet 
          </v-card>
        
          <v-form  
              v-else
                ref="form"
                v-model="valid"
                lazy-validation
                 autocomplete="off"
                style="width: 100%; margin: auto;"
              >
            <v-card class="pa-2" flat>
            
              <v-card-text >
                      <!-- <v-text-field
                        v-model="account_no"
                        :rules="nameRules"
                        label="Enter Account #"
                        required
                        type="number"
                        outlined
                        append-icon="mdi-arrow-right-bold-circle"
                        @click:append="userDetails()"
                      ></v-text-field>

                      <v-text-field
                        v-model="amount"
                        :rules="nameRules"
                        label="Enter Account Amount"
                        required
                        outlined
                         v-if="accountfound"
                        type="number"
                      ></v-text-field> -->
                      <v-autocomplete
                         v-model="account_no"
                         :items="members"
                         outlined
                         dense
                         item-text="fullname"
                         @change="userDetails()"
                         item-value="id"
                          label="Select account"
                      />
                      <table class="print_table3 md-font" style="width: 100%;"   v-if="accountfound">
                     
                      <tr>
                          <td><v-icon left color="secondary" small>mdi-card-account-details</v-icon> Account  #:</td>
                          <td>{{account.id}}</td>
                      </tr>
                        <tr>
                          <td><v-icon left color="secondary" small>mdi-shield-account</v-icon>Username:</td>
                          <td>{{account.username}}</td>
                      </tr>
                      <tr>
                          <td><v-icon left color="secondary" small>mdi-card-account-details-outline</v-icon>Fullname</td>
                          <td>{{account.fullname}}</td>
                      </tr>
                        <tr>
                          <td><v-icon left color="secondary" small>mdi-wallet</v-icon>Balance</td>
                          <td>{{$money(account.balance)}}</td>
                      </tr>
                  </table>

                  <v-text-field
                  class="mt-4"
                        v-model="amount"
                        :rules="nameRules"
                        label="Enter Amount to load"
                        required
                        outlined
                         v-if="accountfound"
                      ></v-text-field>
                    
              </v-card-text>
              <v-card-actions class="pt-4 pb-5">
                  <v-btn
                        color="primary"
                        block
                        v-if="accountfound && amount"
                        rounded
                        large
                        @click="validate()"
                      >
                        Load
                      </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        
        </v-col>
    </v-row>
    <va-win-ticket :show="winticket" :ticket="ticket" @DialogEvent="wtEvent"/>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        winticket: false,
      to_menu: false,
      loading: false,
      valid: false,
      ticket: {},
      account:{},
      members:[],
      accountfound: false,
        search:'',
        amount:null,
        from_date:'',
        account_no:null,
        ticket_code:"",
         nameRules: [
        v => !!v || 'Field is required'
      ],
    }),
    created() {
      this.PING()
       //this.CHECK_BALANCE(this.user.id)
        //this.to_date =  this.$moment().format("YYYY-MM-DD")
        //this.get_wallet_transaction()
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
        this.setOverlayLoading(false)
        this.getMembers()
        }
    },
    computed:{
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
      wtEvent(){
          this.winticket = false
      },
       validate () {
           
      if (this.$refs.form.validate()) {
         this.addLoad()
       }
      },
      getMembers(){
         this.$http.post("user/get_members",{id: this.user.id, role: this.user.role}).then(response => {
             response.data.items != null?this.members = response.data.items:this.members = response.data.items
             console.log(response.data.items)
          }).catch(e => {
            console.log(e.data)
          })
      },
       userDetails(){
          this.account = {}
          this.accountfound = false
          this.$http.post("user/details",{id: this.$IsNum(this.account_no)}).then(response => {
              if(response.data.status) {
                 this.account = response.data.account
                  this.accountfound = true
              } else {
                this.VA_ALERT("error", response.data.message)
              }
          }).catch(e => {
            console.log(e.data)
          })
    },
      addLoad(){
        var param = {}
        param.type = "Transfer Load"
        param.description = "Transfer Load"
        param.amount = this.$IsNum(this.amount.trim())
        param.account = this.account_no
        this.$http.post("wallet/load",param).then(response => {
              if(response.data.status) {
                  this.VA_ALERT('success', response.data.message)
                  this.amount = 0
                  this.account_no = 0
                  this.accountfound = false
                  this.$refs.form.reset()
              } else {
                  this.VA_ALERT('error', response.data.message)
              }
          }).catch(e => {
            console.log(e.data)
          })
      }
    },
  }
</script>